const initialState = {
  alertHeight: "0px",
};
const types = {
  SET_ALERT_HEIGHT: "SET_ALERT_HEIGHT",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ALERT_HEIGHT:
      return {
        ...state,
        alertHeight: action.payload,
      };
      
    default:
      throw new Error("Unexpected action");
  }
};

export { initialState, types, reducer };