import { types } from "./reducers";

export const useActions = (state, dispatch) => {

  function setAlertHeight(str) {
    dispatch({ type: types.SET_ALERT_HEIGHT, payload: str})
  }

  return {
    setAlertHeight,
  };
};
